import {Breadcrumbs, Link as LinkMUI, Stack, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

function BreadcrumbsStatic({options}) {

  const defaultStyles = {
    textDecoration: 'none',
    fontSize: '20px',
    color: 'text.secondary',
    fontWeight: 500,
  };

  const lastStyles = {
    color: 'text.primary',
  };

  return <Breadcrumbs>
    {
      (options || []).map((option, index, array) => {

        const IS_LAST = index === (array.length - 1);
        const SLOT = option?.slot;

        return (
            <Stack
                key={index}
                direction={'row'}
                spacing={2}
                alignItems={'center'}
            >
              <LinkMUI

                  component={option?.route ? Link : Typography}
                  to={option?.route}
                  sx={{
                    ...defaultStyles,
                    ...(IS_LAST && lastStyles),
                  }}
              >
                {option.label}
              </LinkMUI>
              {
                  !!SLOT && SLOT
              }
            </Stack>
        );
      })
    }
  </Breadcrumbs>;
}

export default BreadcrumbsStatic;
