import {GRID_DATE_COL_DEF, GRID_DATETIME_COL_DEF} from '@mui/x-data-grid-premium';
import {getDateFilterOperators} from './dataGridFilterOperators';

export const columnTypeDate = {
  ...GRID_DATE_COL_DEF,
  filterOperators: getDateFilterOperators(false),
};
export const columnTypeDateTime = {
  ...GRID_DATETIME_COL_DEF,
  filterOperators: getDateFilterOperators(true),
};

export const columnTypeNumber = {
  type: 'number',
  align: 'left',
  headerAlign: 'left',
}

export const columnTypeDefaults = {
  resizable: false,
  filterable: true,
  sortable: true,
  flex: 1,
};
